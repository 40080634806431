import sv from "./sv.json";
import en from "./en.json";
import { addTranslations, vueTranslateMixin, addTranslation, currentLocale } from "@telia/b2b-i18n";
import { fetchMicrocopy } from "@telia/b2x-microcopy";

export const translateSetup = async (identifiers) => {
  addTranslations({ sv, en });
  if (identifiers && identifiers.length > 0) {
    const locale = currentLocale();
    addTranslation(await fetchMicrocopy(locale, identifiers), locale);
  }
};
export const translateMixin = vueTranslateMixin;
