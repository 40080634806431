import B2bDeletableItem from "./b2b-deletable-item.ce.vue";
import SpinnerButton from "./components/spinner-button.ce.vue";

const styles = mergeStylesOfComponents(B2bDeletableItem, SpinnerButton);

B2bDeletableItem.styles = styles;

export default B2bDeletableItem;

function mergeStylesOfComponents(...components): string[] {
  return components.flatMap(({ styles }) => styles);
}
