<template>
  <div t-id="deletable-item" class="deletable-item">
    <transition name="fade">
      <div
        class="deletable-item__overlay"
        t-id="deletable-item-overlay"
        v-if="showConfirmDialogAndOverlay"
      ></div>
    </transition>
    <slot />
    <telia-notification
      t-id="deletable-item-error"
      heading-tag="h3"
      status="warning"
      class="deletable-item__error"
      v-if="error"
    >
      <span slot="heading">{{ t("deletableItem.error.heading") }}</span>
      <span slot="content">
        <telia-p>
          {{ t("deletableItem.error.message") }}
        </telia-p>
      </span>
    </telia-notification>
    <div class="deletable-item__slide-transition">
      <transition name="slide" mode="out-in" key="delete-container">
        <div
          t-id="deletable-item-delete-container"
          class="deletable-item__delete-container"
          v-if="showConfirmDialogAndOverlay"
        >
          <spinner-button
            t-id="deletable-item-confirm-delete-button"
            class="deletable-item__confirm-delete-button"
            variant="destructive"
            :disabled="pendingDeletion"
            :active-spinner="pendingDeletion"
            :label="t('deletableItem.delete')"
            @spinner-button-clicked="confirmDelete"
          >
          </spinner-button>
          <telia-button
            t-id="deletable-item-cancel-delete-button"
            @click.prevent="cancelDelete"
            size="sm"
            variant="tertiary-purple"
            :disabled="pendingDeletion"
          >
            {{ t("deletableItem.cancel") }}
          </telia-button>
        </div>
      </transition>
    </div>
    <div
      t-id="deletable-item-delete-container"
      class="deletable-item__delete-container"
      v-if="!showConfirmDialogAndOverlay"
    >
      <telia-button
        t-id="deletable-item-delete-button"
        @click.prevent="toggleConfirmDelete"
        class="deletable-item__delete-button"
        variant="text"
        :left-icon="JSON.stringify({ name: 'delete', size: 'sm' })"
        :text="t('deletableItem.delete')"
      >
      </telia-button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { translateMixin, translateSetup } from "./locale";
import { computed, ref } from "vue";
import SpinnerButton from "./components/spinner-button.ce.vue";

interface Props {
  error?: boolean;
  itemId: string;
  pendingDeletion?: boolean;
}

translateSetup([]);

const props = withDefaults(defineProps<Props>(), {
  error: false,
  pendingDeletion: false,
});

const emit = defineEmits<{
  (e: "delete-item", itemId: string): void;
}>();
const t = translateMixin.methods.t;

const confirmDeleteOpened = ref<boolean>(false);

const showConfirmDialogAndOverlay = computed<boolean>(() => {
  return confirmDeleteOpened.value || (props.pendingDeletion && !props.error);
});

function toggleConfirmDelete() {
  confirmDeleteOpened.value = !confirmDeleteOpened.value;
}

function confirmDelete() {
  emit("delete-item", props.itemId);
  confirmDeleteOpened.value = false;
}

function cancelDelete() {
  toggleConfirmDelete();
}
</script>
<style lang="scss">
@import "~@teliads/components/foundations/colors/tokens";
@import "~@teliads/components/foundations/spacing/tokens";

.deletable-item {
  display: block;
  position: relative;

  &__delete-container {
    position: absolute;
    right: 0;
    top: $telia-spacing-12;
    z-index: 2;
  }

  &__confirm-delete-button {
    margin-right: $telia-spacing-8;
  }

  &__overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }

  &__slide-transition {
    overflow: hidden;
    position: absolute;
    height: 5.5rem;
    top: 0;
    right: 0;
    left: 0;
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: all 0.2s ease-out;
}

.slide-enter-from {
  transform: translate(50%, 0);
  opacity: 1;
}

.slide-leave-to {
  transform: translate(50%, 0);
  opacity: 0;
}

.fade-leave-active,
.fade-enter-active {
  transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
